import React from 'react';
import shop from 'shopify-buy';
import { encode } from 'shopify-gid';
import cookie from 'js-cookie';
import algoliaClient from '../api/algolia';

const productSearchIndex = algoliaClient.initIndex('FENN');

productSearchIndex.setSettings({ hitsPerPage: 1000 });

const CHECKOUT_KEY = 'fennstore_checkout';
const BANNER_KEY = 'fennstore_banner';
const NEWSLETTER_KEY = 'fennstore_newsletter';

const shopify = shop.buildClient({
    domain: process.env.GATSBY_SHOPIFY_DOMAIN,
    storefrontAccessToken: process.env.GATSBY_SHOPIFY_STOREFRONT_API_TOKEN,
});

const ShopContext = React.createContext();
export default ShopContext;

export class ShopContextProvider extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isCartOpen: false,
            isAddingToCart: false,
            isSearchOpen: false,
            products: [],
            shop: {},
            checkout: { lineItems: [] },
            error: null,
            successMessage: null,
            successMessageColor: null,
            isHydratingCheckout: false,
            isSearching: false,
            searchResults: [],
            isMenuOpen: false,
            isShopMenuActive: false,
            hasSeenBanner: false,
            hasSeenNewsletterModal: false,
            backInStockProduct: {
                title: null,
                variantId: null,
                productId: null,
            }
        };
    }

    componentDidMount = () => {
        let productsPromise = shopify.product.fetchAll(250);
        let shopPromise = shopify.shop.fetchInfo();

        Promise.all([productsPromise, shopPromise]).then(response => {
            let products = response[0];
            let shop = response[1];

            this.setState({
                shop,
                products,
            });
        });
        this.hydrateCheckout();

        const hasSeenBanner = Boolean(cookie.get(BANNER_KEY));

        this.setState({hasSeenBanner: hasSeenBanner})

        const hasSeenNewsletter = Boolean(cookie.get(NEWSLETTER_KEY));

        this.setState({hasSeenNewsletterModal: hasSeenNewsletter})
    };

    hydrateCheckout = () => {
        const checkoutId = cookie.get(CHECKOUT_KEY);

        this.setState({ isHydratingCheckout: true });

        if (checkoutId) {
            shopify.checkout
                .fetch(checkoutId)
                .then(checkout => {
                    if (checkout.completedAt) {
                        shopify.checkout.create().then(newCheckout => {
                            this.setState({
                                newCheckout,
                            });
                        });
                    } else {
                        this.setState({
                            checkout,
                        });
                    }
                })
                .catch(error => console.warn(error));
        } else {
            shopify.checkout.create().then(checkout => {
                this.setState({
                    checkout,
                });
            });
        }
    };

    setCartOpen = boolean => {
        this.setState({
            isCartOpen: boolean,
        });
    };

    setSearchOpen = boolean => {
        this.setState({
            isSearchOpen: boolean,
        });
    };

    setAddingToCart = boolean => {
        this.setState({
            isAddingToCart: boolean,
        });;
    };

    addVariantToCart = (variantId, quantity) => {
        this.setState({
            isAddingToCart: true
        })

        const productVariant = encode('ProductVariant', variantId, {
            accessToken: process.env.GATSBY_SHOPIFY_STOREFRONT_API_TOKEN,
        });

        const lineItemsToAdd = [
            { variantId: productVariant, quantity: quantity },
        ];
        const checkoutID = this.state.checkout.id;

        shopify.checkout
            .addLineItems(checkoutID, lineItemsToAdd)
            .then(updatedCheckout => {
                cookie.set(CHECKOUT_KEY, this.state.checkout.id, {
                    expires: 30,
                });
                this.setState({
                    checkout: updatedCheckout,
                    error: null,
                    isCartOpen: true,
                    isAddingToCart: false,
                });
            })
            .catch(error => {
                this.setState({
                    error,
                    successMessage: null,
                });
            });
    };

    addAllToCart = async (variantIds = []) => {
        this.setState({
            isAddingToCart: true
        })

        const bundleLineItems = variantIds.map(id => {
            const productVariant = encode('ProductVariant', id, {
                accessToken: process.env.GATSBY_SHOPIFY_STOREFRONT_API_TOKEN,
            });
            return { variantId: productVariant, quantity: 1 }

        })

        const checkoutID = this.state.checkout.id;

        shopify.checkout
            .addLineItems(checkoutID, bundleLineItems)
            .then(updatedCheckout => {
                cookie.set(CHECKOUT_KEY, this.state.checkout.id, {
                    expires: 30,
                });
                this.setState({
                    checkout: updatedCheckout,
                    error: null,
                    isCartOpen: true,
                    isAddingToCart: false,
                });
            })
            .catch(error => {
                this.setState({
                    error,
                    successMessage: null,
                });
            });




    };

    updateQuantityInCart = (lineItemID, quantity) => {
        const checkoutID = this.state.checkout.id;
        const lineItemsToUpdate = [
            { id: lineItemID, quantity: Number(quantity) },
        ];

        shopify.checkout
            .updateLineItems(checkoutID, lineItemsToUpdate)
            .then(updatedCheckout => {
                cookie.set(CHECKOUT_KEY, this.state.checkout.id, {
                    expires: 30,
                });
                this.setState({
                    checkout: updatedCheckout,
                });
            });
    };

    removeLineItemInCart = lineItemID => {
        const checkoutID = this.state.checkout.id;

        shopify.checkout
            .removeLineItems(checkoutID, [lineItemID])
            .then(updatedCheckout => {
                this.setState({
                    checkout: updatedCheckout,
                });
            });
    };

    clearSuccessMessage = () => {
        this.setState({
            successMessageColor: null,
            successMessage: null,
        });
    };

    fetchProduct = async productId => {
        const productVariant = encode('Product', productId, {
            accessToken: process.env.GATSBY_SHOPIFY_STOREFRONT_API_TOKEN,
        });

        const product = await shopify.product.fetch(productVariant)

        return product;
    };

    setIsSearching = value => {
        this.setState({
            isSearching: value,
        });
    };

    setIsMenuOpen = value => {
        this.setState({
            isMenuOpen: value,
        });
    };

    setIsShopMenuActive = value => {
        this.setState({
            isShopMenuActive: value,
        });
    };

    setHasSeenBanner = value => {
        this.setState({
            hasSeenBanner: value,
        });
        cookie.set(BANNER_KEY, true, { expires: 1});
    };

    setHasSeenNewsletterModal = value => {
        this.setState({
            hasSeenNewsletterModal: value,
        });
        cookie.set(NEWSLETTER_KEY, true, { expires: 1});
    };

    searchStore = searchTerm => {
        this.setState({
            isSearching: true,
        });
        productSearchIndex
            .search(searchTerm)
            .then(({ hits }) => {
                this.setState({
                    searchResults: hits,
                    isSearching: false,
                });
            })
            .catch(error => {
                console.log(error);
                this.setState({
                    isSearching: false,
                });
            });
    };

    setBackInStockProduct = ({title, variantId, productId}) => {
        this.setState({
            backInStockProduct: {
                title,
                variantId,
                productId
            }
        })
    }

    render() {
        const value = {
            // values
            products: this.state.products,
            shop: this.state.shop,
            checkout: this.state.checkout,
            isCartOpen: this.state.isCartOpen,
            isAddingToCart: this.state.isAddingToCart,
            isSearchOpen: this.state.isSearchOpen,
            successMessage: this.state.successMessage,
            successMessageColor: this.state.successMessageColor,
            isSearching: this.state.isSearching,
            searchResults: this.state.searchResults,
            isMenuOpen: this.state.isMenuOpen,
            isShopMenuActive: this.state.isShopMenuActive,
            hasSeenBanner: this.state.hasSeenBanner,
            hasSeenNewsletterModal: this.state.hasSeenNewsletterModal,
            backInStockProduct: this.state.backInStockProduct,

            // methods
            hydrateCheckout: this.hydrateCheckout,
            setCartOpen: this.setCartOpen,
            setAddingToCart: this.setAddingToCart,
            addAllToCart: this.addAllToCart,
            setSearchOpen: this.setSearchOpen,
            addVariantToCart: this.addVariantToCart,
            updateQuantityInCart: this.updateQuantityInCart,
            removeLineItemInCart: this.removeLineItemInCart,
            setSanityProducts: this.setSanityProducts,
            clearSuccessMessage: this.clearSuccessMessage,
            fetchProduct: this.fetchProduct,
            searchStore: this.searchStore,
            setIsSearching: this.setIsSearching,
            setIsMenuOpen: this.setIsMenuOpen,
            setIsShopMenuActive: this.setIsShopMenuActive,
            setHasSeenBanner: this.setHasSeenBanner,
            setHasSeenNewsletterModal: this.setHasSeenNewsletterModal,
            setBackInStockProduct: this.setBackInStockProduct,
        };

        return (
            <ShopContext.Provider value={value}>
                {this.props.children}
            </ShopContext.Provider>
        );
    }
}
