// Polyfills
require('unfetch');

const React = require('react');
const Layout = require('./src/components/Layout.jsx').default;
const { wrapRootElement } = require('./wrapRootElement');
const { Initialize } = require('./src/components/Analytics.jsx')

// Wrap every page with the main layout
exports.wrapPageElement = ({ element, props }) => (
  <Layout {...props}>{element}</Layout>
);

exports.wrapRootElement = wrapRootElement;

const analytics = Initialize({
  // Setup analytics
  facebookPixelId: process.env.GATSBY_FB_PIXEL_ID,
  googleAnalyticsPropertyId: process.env.GATSBY_GA_ID,
  googleLinkerDomains: [
    "fennstore.com",
    "shop.fennstore.com",
    "fenn-store.myshopify.com",
  ],
});

window.analytics = analytics;

exports.onRouteUpdate = ({ location }) => {
  analytics.pageView(location);
};

exports.onInitialClientRender = () => {
    window.dataLayer = window.dataLayer || [];
};

// Credits
console.log(`
Site Credit
===========
Design: http://www.dtpractice.co.uk/
Development : https://buena-suerte.studio
`);
