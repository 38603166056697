import React, { useContext, useEffect } from 'react';
import Context from '../context/Context';
import Analytics from './Analytics';
import loadable from '@loadable/component';
import '../styles/main.scss';
import cx from 'classnames';

import { usePrevious } from '../hooks/usePrevious';

import {
    LazyMotion,
    domAnimation,
    m as motion,
    AnimatePresence,
} from 'framer-motion';

const Header = loadable(() => import('./Header'));
const Footer = loadable(() => import('./Footer'));
const Cart = loadable(() => import('./Cart'));
const SearchForm = loadable(() => import('./SearchForm'));
const NewsletterModal = loadable(() => import('./NewsletterModal'));
const BackInStockPopup = loadable(() => import('./BackInStockPopup'));

export const TRANSITION_DURATION = 400;

const TRANSITION_STYLES = {
    default: {
        transition: `opacity ${TRANSITION_DURATION}ms ease`,
    },
    entering: {
        opacity: 0,
    },
    entered: {
        opacity: 1,
    },
    exiting: {
        opacity: 0,
    },
    exited: {
        opacity: 0,
    },
};

const Layout = ({ pageContext, children, location }) => {
    const { header, footer, newsletter } = pageContext;

    const {
        setIsMenuOpen,
        setIsShopMenuActive,
        isSearchOpen,
        isMenuOpen,
        isShopMenuActive,
        hasSeenBanner,
        checkout,
    } = useContext(Context);

    const { lineItems = [] } = checkout || {};

    const prevPath = usePrevious(location.pathname);

    // run on every route change
    useEffect(() => {
        if (prevPath !== location.pathname) {
            window.ElevarGtmSuiteAAT &&
                window.ElevarGtmSuiteAAT.utils.pushContextToDataLayer();
        }

        const userDataItems = lineItems.map((product, index) => ({
            id: product.id
                .split('CheckoutLineItem/')[1]
                .split('?')[0]
                .toString(),
            product_id: product.id
                .split('CheckoutLineItem/')[1]
                .split('?')[0]
                .toString(),
            variant_id: product.variant.id
                .split('ProductVariant/')[1]
                .toString(),
            name: product.title,
            title: product.title,
            category: '',
            brand: '',
            price: product.variant.price.amount,
            list: location.pathname,
            image: product?.thumbnail?.url || '',
            position: index + 1,
            variant: product.variant.title,
            quantity: product.quantity.toString(),
        }));
        window.analytics &&
            window.analytics.elevarUserData(
                checkout?.subtotalPrice ? checkout.subtotalPrice.amount : '0.0',
                userDataItems
            );
    }, [location.pathname]);

    const resizeMenu = () => {
        if (window.innerWidth > 1000) {
            setIsMenuOpen(false);
        } else {
            setIsShopMenuActive(false);
        }
    };

    useEffect(() => {
        window.addEventListener('resize', resizeMenu);
        return () => window.removeEventListener('resize', resizeMenu);
    }, []);

    return (
        <LazyMotion features={domAnimation} strict>
            <Analytics
                facebookPixelId={process.env.GATSBY_FB_PIXEL_ID}
                klaviyoId={process.env.GATSBY_KLAVIYO_COMPANY}
            />
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: TRANSITION_DURATION / 1000 }}
            >
                <Header location={location.pathname} {...header} />
                <Cart location={location} enableBanner={!hasSeenBanner} />
                <SearchForm enableBanner={!hasSeenBanner} />
                {/* <NewsletterModal {...newsletter} /> */}

                <motion.main
                    key={location.pathname}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: TRANSITION_DURATION / 1000 }}
                    id="maincontent"
                    className={cx('pr', {
                        'banner-open': !hasSeenBanner,
                        blur: isMenuOpen || isShopMenuActive || isSearchOpen,
                    })}
                >
                    {children}
                    <Footer {...footer} />
                </motion.main>
            </motion.div>
            <BackInStockPopup />
        </LazyMotion>
    );
};

export default Layout;
