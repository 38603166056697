exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-404-jsx": () => import("./../../../src/templates/404.jsx" /* webpackChunkName: "component---src-templates-404-jsx" */),
  "component---src-templates-about-jsx": () => import("./../../../src/templates/About.jsx" /* webpackChunkName: "component---src-templates-about-jsx" */),
  "component---src-templates-book-appointment-jsx": () => import("./../../../src/templates/BookAppointment.jsx" /* webpackChunkName: "component---src-templates-book-appointment-jsx" */),
  "component---src-templates-brand-detail-page-jsx": () => import("./../../../src/templates/BrandDetailPage.jsx" /* webpackChunkName: "component---src-templates-brand-detail-page-jsx" */),
  "component---src-templates-brands-jsx": () => import("./../../../src/templates/Brands.jsx" /* webpackChunkName: "component---src-templates-brands-jsx" */),
  "component---src-templates-collection-jsx": () => import("./../../../src/templates/Collection.jsx" /* webpackChunkName: "component---src-templates-collection-jsx" */),
  "component---src-templates-contact-jsx": () => import("./../../../src/templates/Contact.jsx" /* webpackChunkName: "component---src-templates-contact-jsx" */),
  "component---src-templates-home-jsx": () => import("./../../../src/templates/Home.jsx" /* webpackChunkName: "component---src-templates-home-jsx" */),
  "component---src-templates-journal-entry-jsx": () => import("./../../../src/templates/JournalEntry.jsx" /* webpackChunkName: "component---src-templates-journal-entry-jsx" */),
  "component---src-templates-journal-list-jsx": () => import("./../../../src/templates/JournalList.jsx" /* webpackChunkName: "component---src-templates-journal-list-jsx" */),
  "component---src-templates-page-jsx": () => import("./../../../src/templates/Page.jsx" /* webpackChunkName: "component---src-templates-page-jsx" */),
  "component---src-templates-preview-jsx": () => import("./../../../src/templates/Preview.jsx" /* webpackChunkName: "component---src-templates-preview-jsx" */),
  "component---src-templates-product-jsx": () => import("./../../../src/templates/Product.jsx" /* webpackChunkName: "component---src-templates-product-jsx" */),
  "component---src-templates-search-jsx": () => import("./../../../src/templates/Search.jsx" /* webpackChunkName: "component---src-templates-search-jsx" */),
  "component---src-templates-treatment-jsx": () => import("./../../../src/templates/Treatment.jsx" /* webpackChunkName: "component---src-templates-treatment-jsx" */),
  "component---src-templates-treatments-index-jsx": () => import("./../../../src/templates/TreatmentsIndex.jsx" /* webpackChunkName: "component---src-templates-treatments-index-jsx" */)
}

