import React from 'react';
import Helmet from 'react-helmet';
import fetch from 'unfetch';

//
// === Initialize global Analytics ===
//


export const Initialize = () => {
    const elevarUserData = async (cart_total, products) => {
        window.ElevarPushToDataLayer &&
            window.ElevarPushToDataLayer({
                event: 'dl_user_data',
                cart_total: cart_total.toString(),
                user_properties: {
                    // The following fields are required
                    user_consent: '', // Use an empty string
                    visitor_type: 'guest', // "logged_in" || "guest"
                },
                ecommerce: {
                    currencyCode: 'AUD',
                    cart_contents: {
                        products, // See the products array below
                    },
                },
            });
    };

    const pageView = async location => {
        // global.fbq && global.fbq('track', 'PageView');

        window.gtag &&
            window.gtag('config', 'UA-193827502-1', {
                page_path: location,
                linker: {
                    domains: [
                        'fenn-store.myshopify.com',
                        'shop.fennstore.com/',
                        'www.fennstore.com/',
                        'fennstore.com/',
                    ],
                },
            });
    };

    const collectionView = async impressions => {
        window.ElevarPushToDataLayer &&
            window.ElevarPushToDataLayer({
                event: 'dl_view_item_list',
                ecommerce: {
                    currencyCode: 'AUD',
                    impressions,
                },
            });
    };

    const searchPageView = async impressions => {
        window.ElevarPushToDataLayer &&
            window.ElevarPushToDataLayer({
                event: 'dl_view_search_results',
                ecommerce: {
                    currencyCode: 'AUD',
                    actionField: {
                        list: 'search results',
                    },
                    impressions,
                },
            });
    };

    const productClick = async (location, product = {}) => {
        window.ElevarPushToDataLayer &&
            window.ElevarPushToDataLayer({
                event: 'dl_select_item',
                ecommerce: {
                    currencyCode: 'AUD',
                    click: {
                        actionField: {
                            list: location.pathname,
                            action: 'click',
                        },
                        products: [product],
                    },
                },
            });
    };

    const productView = async (location, product = {}) => {
        window.ElevarPushToDataLayer &&
            window.ElevarPushToDataLayer({
                event: 'dl_view_item',
                ecommerce: {
                    currencyCode: 'AUD',
                    detail: {
                        actionField: {
                            list: location.pathname, // this should be the collection page URL that user clicked product from
                            action: 'detail',
                        },
                        products: [product], // See the products array below
                    },
                },
            });
    };

    const addToCart = async (location, product = {}) => {
        window.ElevarPushToDataLayer &&
            window.ElevarPushToDataLayer({
                event: 'dl_add_to_cart',
                ecommerce: {
                    currencyCode: 'AUD',
                    add: {
                        actionField: {
                            list: location.pathname, // this should be the collection page URL that user clicked product from
                        },
                        products: [product],
                    },
                },
            });
    };

    const removeFromCart = (location, product = {}) => {
        window.ElevarPushToDataLayer &&
            window.ElevarPushToDataLayer({
                event: 'dl_remove_from_cart',
                ecommerce: {
                    currencyCode: 'AUD',
                    remove: {
                        actionField: {
                            list: location.pathname,
                        },
                        products: [product],
                    },
                },
            });
    };

    const viewCart = (cart_total, impressions) => {
        window.ElevarPushToDataLayer &&
            window.ElevarPushToDataLayer({
                event: 'dl_view_cart',
                cart_total: cart_total.toString(),
                ecommerce: {
                    currencyCode: 'AUD',
                    actionField: {
                        list: 'Shopping Cart',
                    },
                    impressions,
                },
            });
    };

    const initiateCheckout = async () => {
        global.gtag && global.gtag('event', 'Initiate Checkout');
    };

    return {
        elevarUserData,
        pageView,
        viewCart,
        collectionView,
        searchPageView,
        searchPageView,
        productView,
        productClick,
        addToCart,
        removeFromCart,
        initiateCheckout,
    };
};

//
// === Group all script tags here` ===
//

const Analytics = ({ facebookPixelId, klaviyoId }) => (
    <Helmet>
        {/* Elevar */}
        <script id="elevar-dl-listener-config" type="application/json">
            {`{"data_layer_listener_script": "https://shopify-gtm-suite.getelevar.com/shops/b728c5aa8e4190fc96579672620ce510ca3ba8aa/3.5.0/events.js", "ss_url": "https://hits.getelevar.com", "signing_key": "oedG43jr71B8FQ4K4DPYLAkvfLl7yRkg", "myshopify_domain": "fenn-store.myshopify.com"}`}
        </script>

        <script>
            {`
(() => {
    if (!window.__ElevarIsGtmSuiteListenerCalled) {
      window.__ElevarIsGtmSuiteListenerCalled = true;
      const configElement = document.getElementById(
        "elevar-dl-listener-config"
      );

      if (!configElement) {
        console.error("Elevar: DLL Config element not found");
        return;
      }

      const config = JSON.parse(configElement.textContent);

      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src = config.data_layer_listener_script;
      script.async = false;
      script.defer = true;

      script.onerror = function () {
        console.error("Elevar: DLL JS script failed to load");
      };
      script.onload = function () {
        if (!window.ElevarGtmSuiteListener) {
          console.error("Elevar: \`ElevarGtmSuiteListener\` is not defined");
          return;
        }

        window.ElevarGtmSuiteListener.handlers.listen({
          ssUrl: config.ss_url,
          signingKey: config.signing_key,
          myshopifyDomain: config.myshopify_domain
        });
      };

      document.head.appendChild(script);
    }
  })();
        `}
        </script>
        <script id="elevar-dl-aat-config" type="application/json">
            {`{"data_layer_aat_script": "https://shopify-gtm-suite.getelevar.com/shops/b728c5aa8e4190fc96579672620ce510ca3ba8aa/3.5.0/aat.js", "apex_domain": "fennstore.com", "consent_enabled": false}`}
        </script>
        <script>
            {`
                (() => {
    if (!window.__ElevarIsGtmSuiteAATCalled) {
      window.__ElevarIsGtmSuiteAATCalled = true;
      const init = () => {
  window.__ElevarDataLayerQueue = [];
  window.__ElevarListenerLoadQueue = [];
  if (!window.dataLayer) window.dataLayer = [];
};
      init();
      window.__ElevarTransformItem = event => {
  if (typeof window.ElevarTransformFn === "function") {
    try {
      const result = window.ElevarTransformFn(event);
      if (typeof result === "object" && !Array.isArray(result) && result !== null) {
        return result;
      } else {
        console.error("Elevar Data Layer: \`window.ElevarTransformFn\` returned a value " + "that wasn't an object, so we've treated things as if this " + "function wasn't defined.");
        return event;
      }
    } catch (error) {
      console.error("Elevar Data Layer: \`window.ElevarTransformFn\` threw an error, so " + "we've treated things as if this function wasn't defined. The " + "exact error is shown below.");
      console.error(error);
      return event;
    }
  } else {
    return event;
  }
};
      window.ElevarPushToDataLayer = item => {
  const date = new Date();
  localStorage.setItem("___ELEVAR_GTM_SUITE--lastDlPushTimestamp", String(Math.floor(date.getTime() / 1000)));
  const enrichedItem = {
    event_id: window.crypto.randomUUID ? window.crypto.randomUUID() : String(Math.random()).replace("0.", ""),
    event_time: date.toISOString(),
    ...item
  };
  const transformedEnrichedItem = window.__ElevarTransformItem ? window.__ElevarTransformItem(enrichedItem) : enrichedItem;
  const listenerPayload = {
    raw: enrichedItem,
    transformed: transformedEnrichedItem
  };
  const getListenerNotifyEvent = () => {
    return new CustomEvent("elevar-listener-notify", {
      detail: listenerPayload
    });
  };
  if (transformedEnrichedItem._elevar_internal?.isElevarContextPush) {
    window.__ElevarIsContextSet = true;
    window.__ElevarDataLayerQueue.unshift(transformedEnrichedItem);
    if (window.__ElevarIsListenerListening) {
      window.dispatchEvent(getListenerNotifyEvent());
    } else {
      window.__ElevarListenerLoadQueue.unshift(listenerPayload);
    }
  } else {
    window.__ElevarDataLayerQueue.push(transformedEnrichedItem);
    if (window.__ElevarIsListenerListening) {
      window.dispatchEvent(getListenerNotifyEvent());
    } else {
      window.__ElevarListenerLoadQueue.push(listenerPayload);
    }
  }
  if (window.__ElevarIsContextSet) {
    while (window.__ElevarDataLayerQueue.length > 0) {
      window.dataLayer.push(window.__ElevarDataLayerQueue.shift());
    }
  }
};

      const configElement = document.getElementById("elevar-dl-aat-config");

      if (!configElement) {
        console.error("Elevar: AAT Config element not found");
        return;
      }

      const config = JSON.parse(configElement.textContent);

      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src = config.data_layer_aat_script;
      script.async = false;
      script.defer = true;

      script.onerror = () => {
        console.error("Elevar: AAT JS script failed to load");
      };
      script.onload = async () => {
        if (!window.ElevarGtmSuiteAAT) {
          console.error("Elevar: \`ElevarGtmSuiteAAT\` is not defined");
          return;
        }

        window.ElevarGtmSuiteAAT.utils.emailCapture();

        await window.ElevarGtmSuiteAAT.handlers.register({
          apexDomain: config.apex_domain,
          isConsentEnabled: config.consent_enabled
        });
      };

      document.head.appendChild(script);
    }
  })();
            `}
        </script>

        <script type="module">
            {` try {
    const response = await fetch("https://shopify-gtm-suite.getelevar.com/configs/b728c5aa8e4190fc96579672620ce510ca3ba8aa/config.json");
    const config = await response.json();
    const scriptUrl = config.script_src_custom_pages;

    if (scriptUrl) {
      const { handler } = await import(scriptUrl);
      await handler(config);
    }
  } catch (error) {
    console.error("Elevar Error:", error);
  }`}
        </script>

        {/* Google Verification */}
        <meta
            name="google-site-verification"
            content="7CnQMOtFD3ZkEZXBCdfFZblzZRlnOttSpHLSYygNYOE"
        />
        {/* Facebook */}
        {/* <meta
            name="facebook-domain-verification"
            content="jtwgqm0bbk20tfcyjbno49rreeeg5i"
        />
        {facebookPixelId && (
            <script>{`
          !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '${facebookPixelId}');
        `}</script>
        )}
        {facebookPixelId && (
            <noscript>{`
          <img
            height="1"
            width="1"
            style="display:none"
            src="https://www.facebook.com/tr?id=${facebookPixelId}&ev=PageView&noscript=1"
          />
        `}</noscript>
        )} */}
        {/* Klaviyo */}
        {/* {klaviyoId && (
            <script
                type="application/javascript"
                async
                src={`https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=${klaviyoId}`}
            />
        )} */}
    </Helmet>
);

export default Analytics;